import React from "react";
import { Link } from "react-scroll";

export default function Navbar() {
  return (
    <section
      className="sticky-top navigation"
      style={{
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(9.1px)",
        WebkitBackdropFilter: "blur(9.1px)", // Note the capitalization for vendor prefixes in React inline styles
      }}
    >
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-dark">
          <a className="navbar-brand p-0" href="/">
            <img src="/images/logo.png" alt="logo" style={{ width: "250px" }} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navigation"
            aria-controls="navigation"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navigation">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link
                  activeClass="active"
                  to="material"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="nav-link"
                >
                  technology
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  activeClass="active"
                  to="applications"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="nav-link"
                >
                  applications
                </Link>
              </li> */}
              <li className="nav-item">
                {/* <Link
                  activeClass="active"
                  to="machine"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="nav-link"
                >
                  machine
                </Link> */}
              </li>
              <li className="nav-item">
                <Link
                  activeClass="active"
                  to="order"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="nav-link"
                >
                  inquire
                </Link>
              </li>

              <li className="nav-item">
                {/* <Link activeClass="active" spy={true} smooth={true} offset={-70} duration={500} className="nav-link">
                  Login
                </Link> */}
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </section>
  );
}
